.btn.disabled,
.btn:disabled {
  opacity: 0.3;
}

.table-custom th {
  font-size: 12px;
  vertical-align: middle !important;
}

.table-custom tr {
  font-size: 12px;
}

.table-custom td {
  vertical-align: middle;
}

.container {
  min-width: 90% !important;
}

.flag-select img {
  width: 1.3em;
  height: 1.3em;
  position: relative;
  vertical-align: baseline !important;
  top: 0.3em;
}

.filepond--panel-root {
  border-radius: 0px 0px 5px 5px !important;
}

.dropdown-toggle {
  font-size: 0.864rem;
}

.tabs-below .nav-tabs {
  border-bottom: none;
}

.tabs-below .nav-tabs .nav-item {
  margin-top: -1px;
  margin-bottom: -1px;
}

.tabs-below .tab-content {
  margin-bottom: 30px;
  padding-bottom: 40px;
  border: 1px solid #ddd;
  border-top: none;
  position: relative;
}

.tabs-below .nav-tabs {
  border: 1px solid #ddd;
  border-radius: 0 0 3px 3px;
  background: white;
  position: absolute;
  bottom: 0;
  width: 100%;
}


.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 50%; 
  transform: translateY(-50%);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px; 
  height: 40px; 
  background-color: rgba(0, 0, 0, 0.7); 
  border-radius: 50%; 
  color: #fff; 
  text-align: center;
  opacity: 0.8; 
  margin: 20px;
  transition: opacity 0.15s ease;
}

.carousel-control-next:hover,
.carousel-control-prev:hover {
  opacity: 1;
}
.carousel-caption {
  background-color: rgba(0, 0, 0, 0.7); 
}

.file-pond-text {
  /* background-color: #f1f0ef; */
  border-radius: 5px 5px 0px 0px !important;
  padding: 5px;
  font-size: 1rem;
  text-align: center;
  color: #4f4f4f;
}

.filepond--drop-label {
  color: #4f4f4f;
  background: rgb(199, 197, 197);
  border-radius: 2rem;
  font-family: "Montserrat"
}

.filepond--wrapper {
  max-height: 10rem;
  overflow: auto;
}

.project-global-goals {
  padding: 5px;
  text-align: center;
  display: inline-block;
}

.project-global-goals img {
  cursor: pointer;
  width: 5rem;
  height: 5rem;
}

.project-list-global-goals-img {
  display: inline-block;
  margin: 1px;
  width: 20px;
}

.project-public-global-goals-img {
  display: inline-block;
  margin: 1px;
  width: 70px;
}

.project-list-imported-img {
  cursor: pointer;
  width: 40px;
}

.project-public-imported-img {
  width: 70px;
}

.info-user {
  font-size: 13px;
  margin-left: -13px;
  margin-right: -13px;
  margin-top: -13px;
  text-align: center;
  background: #ccc;
  padding: 5px;
  border: 1px #e6e6e6 red;
}

.svgSuccess {
  width: 100px;
  display: block;
  margin: 40px auto 0;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}

.path .circle {
  -webkit-animation: dash 0.9s ease-in-out;
  animation: dash 0.9s ease-in-out;
}

.path .line {
  stroke-dashoffset: 1000;
  -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
  animation: dash 0.9s 0.35s ease-in-out forwards;
}

.path .check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
  animation: dash-check 0.9s 0.35s ease-in-out forwards;
}

.success {
  text-align: center;
  margin: 20px 0 60px;
  font-size: 1.25em;
  color: #73af55;
}

.tabCustom {
  width: 33%;
  text-align: center;
  color: #4f4f4f;
}

.tabCustomProjectsList {
  width: 50%;
  text-align: center;
  color: #4f4f4f;
}

.tabCustomMyPosition {
  width: 30%;
  text-align: center;
  /* color: #4f4f4f; */
}

.nav-tabs .active {
  color: white !important;
  background-color: #59B089 !important;
  font-weight: bold;
}

.nav-tabs ::selection {
  background: green !important;
}
.modal-backdrop.show {
  width: 100%;
  height: 100%;
}
body {
  background-color: #59B089;
}

@media (min-width: 768px) and (max-width: 992px) {
  .main {
    width: 768px;
    margin: 0 auto;
    /* Center the container */
  }
}

/* Stepper */
.stepper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.step {
  min-width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}


.step-number {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #d3d3d3;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  margin-bottom: 10px;
  border: 2px solid #3f51b5;
}

.step-title {
  font-size: 14px;
  color: #333;
}

.step.active .step-number {
  background-color: #3f51b5;
  color: white;
}

.step.active::after {
  background-color: #3f51b5;
}

.step.active::before {
  background-color: #3f51b5;
}

.step:not(.active) .step-number {
  border: 2px solid #3f51b5;
}

.step:not(.active) .step-number::before {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #3f51b5;
}


.btn-info {
  border-radius: 37px;
  margin-bottom: 10px;
  background: #59B089 !important;
  border: none;
}

.btn {
  border-radius: 37px;
  font-family: "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif !important;
  margin-bottom: 0px;
}

/* Hexagon */

.hexagon {
  position: relative;
  height: 35px;
  width: 35px;
  background: #59B089;
}

.hexagon:before {
  position: absolute;
  content: '';
}

.hexagon:before {
  top: 4px;
  left: 4px;
  height: calc(100% - 8px);
  width: calc(100% - 8px);
  background: #59B089;
}

.hexagon,
.hexagon:before {
  clip-path: polygon(0% 25%, 50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%);
}

/* Hexagon with border */

.hexagon-bordered {
  position: relative;
  height: 35px;
  width: 35px;
  margin: auto;
  background: #59B089;
}

.hexagon-bordered:before {
  position: absolute;
  content: '';
}

.hexagon-bordered:before {
  top: 4px;
  left: 4px;
  height: calc(100% - 8px);
  width: calc(100% - 8px);
  background: white;
}

.hexagon-bordered,
.hexagon-bordered:before {
  -webkit-clip-path: polygon(0% 25%, 50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%);
  clip-path: polygon(0% 25%, 50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%);
}


.hexagon,
.hexagon-bordered {
  flex-shrink: 0;
}

.step-line {
  width: 50px;
  height: 2px;
  background-color: #59B089;
  border: 0.5px solid #59B089;
}

.step-line-hidden {
  width: 50px;
  height: 2px;
  background-color: white;
  border: 0.5px solid white;
}



.btn-gap {
  margin-bottom: 0px;
}



@media (max-width: 768px) {
  .stepper {
    width: 210%;
    display: none;
  }
}

@media (max-width: 576px) {
  .stepper {
    width: 210%;
    display: none;
  }
}

.colcx-btn {
  border-radius: 34px;
}

.colcx-table-cell-header {
  padding: 15px 8px 15px 10px !important;
  background: #6f6f6f !important;
  color: white !important;
  font-family: "Montserrat" !important;
  gap: 50px !important;
  font-size: 0.875rem !important;
  text-align: center !important;
  /*padding:0rem  !important;*/
}

.colcx-table-cell-info {
  padding: 5px 10px 5px 10px !important;
  font-weight: "normal" !important;
  font-size: 0.875rem !important;
  text-align: center !important;
  font-family: "Montserrat" !important;
  width: 17.5rem !important;
  max-width: 16rem !important;
  max-height: 0.3rem !important;
  
}


.helper{
  position: relative;
  display: inline-block;
}

.helper-content {
  display: inline-block; 
  position: relative; 
}

.helper-content__information {
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute;
  z-index: -10;
  bottom: 100%;
  line-height: 1.1;
  min-width: 200px;
  left: 50%;
  transform: translateX(-48%);
  color: white;
}

.helper-content:hover + .helper-content__information{
  opacity: 1;
  z-index: 10;
}


/* .filepond--file-info-sub {
  display: none; //Oculta el tamaño del archivo 
} */
.release-btn {
  background-color: transparent;
  border-color: #59b089;
  color: #59b089;
  transition: background-color 0.3s, border-color 0.3s;
}

.release-btn:hover,
.release-btn:focus,
.release-btn:active,
.release-btn.active {
  background-color: #59b089 !important;
  border-color: #59b089 !important;
  color: white !important;
  outline: none; 
  box-shadow: none; 
}


.nav-link {
  color: gray 
}

.nav-tabs .nav-link:hover {
  color: gray; 
}

.colcx-table-cell-info-left {
  padding: 5px 0px 5px 0px !important;
  font-weight: "normal" !important;
  font-size: 0.875rem !important;
  text-align: left !important;
  font-family: "Montserrat" !important;
  width: 17.5rem !important;
  max-width: 16rem !important;
  max-height: 0.3rem !important;
  
}

.colcx-table {
  border-radius: 20px !important;
  font-family: "Montserrat" !important
}

/* Main font */
.css-10hburv-MuiTypography-root,
.css-bwhwlk-MuiModal-root-MuiPopover-root-MuiMenu-root .MuiPaper-root .MuiMenuItem-root,
.css-d3wcwz-MuiTypography-root,
.css-d3wcwz-MuiTypography-root, .main-font, .css-1u4ma7l-MuiChip-root {
  font-family: "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif !important;
  margin: 0
}

.main-font-style {
  font-family: "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif !important;
  color: rgb(89, 176, 137);
  font-weight: 700;
  text-transform: none;
}

.css-h4y409-MuiList-root {
  background-color: white !important;
}

/* Sidebar color white */
.css-2dum1v-MuiDrawer-docked .MuiDrawer-paper {
  background-color: white !important;
}


@media (max-width: 768px) {
  .hide-sm {
    display: none;
  }
}

.modal-content {
  border-radius: 30px !important;
  opacity: 1;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

/* Dialog paper style */
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 30px !important;
  opacity: 1;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

/* Dialog component title style */
.css-140puxv-MuiTypography-root-MuiDialogTitle-root {
    color: #59B089 !important;
    font-family: "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 700 !important;
    margin: 0
}

.form-control-invalid-international {
  border: 1px solid #FF0000 !important;
}

.react-international-phone-country-selector-dropdown {
  z-index: 20 !important;
}

.react-international-phone-input {
  width: 100% !important;
}

/* Spinner */
/* HTML: <div class="loader"></div> */
.loader {
  height: 120px;
  width: 120px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,#646687 94%,#0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%, #646687);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation: l13 1s infinite linear;
}
@keyframes l13{ 
  100%{transform: rotate(1turn)}
}