@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
/*@import Cuidado2 from "./../images/cuidado2.png";*/
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Playwrite+DK+Uloopet:wght@100..400&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
html {
  scroll-behavior: smooth;
}

.OverlayTrigger{
  background-color: #59B089;
  background: #59B089;
}
body {
  margin: 0;
  padding: 0;
  
  background-color: white;
  background-image: none; /* o cualquier otra configuración específica */
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  /* font-family: "Nunito", sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  /* font-weight: <weight>; */
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.new-background {
  background-image: url('./images/background_colcx.png');
  background-repeat: no-repeat;
  background-size: cover; 
  background-attachment: fixed; 
  background-position: center;
  height: auto;
}
code {
  font-family:"Montserrat", sans-serif;
}


.MuiDrawer-paper {
  background-color: rgb(52, 58, 64);
}
@media (min-width: 768px) and (max-width: 992px) {
  .main {
    width: 768px;
    margin: 0 auto; /* Center the container */
  }
}