.filepond--hopper {
  margin: 0px
}

.filepond--wrapper {
  border: none; 
  background: white;
}

.filepond--item {
  background-color: white !important;
}


.filepond--drop-label {
  background-color: white !important;
}

.filepond--panel-root {
  background-color: white !important;
}

.filepond--root {
  background-color: white;
}


.card-header-title {
  color: #59B089;
  padding-left: 1rem;
  font-weight: 700;
  margin: 0
}
.card-custom-header {
  background: #59B08933;
   border-top-left-radius: 30px !important;
   border-top-right-radius: 30px !important;
}
.custom-card {
  border-radius: 30px;
  opacity: 1;
  margin-bottom: 2rem;
  width: 100%;
  /*transition: border-color 0.3s, opacity 0.3s;*/
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  /*transition: box-shadow 0.3s;*/
}

.custom-card-height {
  min-height: "auto";
}

.custom-card:focus-within {
  border-color: #007bff;
  opacity: 1;
  box-shadow: None
}

/*.animation1 {
    /*animation: moveCircle 2s ease-in-out ;

  }*/
.animation2 {

  opacity: 1;
  margin-left: 20px;
  /*animation: fadeText 2s ease-in-out;*/

}

@keyframes moveCircle {
  from {
    transform: translateX(250px);
  }

  to {
    transform: translateX(0px);
  }
}

@keyframes fadeText {

  0%,
  40%,
  60% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.table-container {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.table-custom {
  margin: 0;
}

.table-custom thead th {
  background-color: #1e554a;
  font-size: 0.8rem;
  color: #fff;
  font-weight: bold;
  border-bottom: 2px solid #e6e6e6;
}

.table-custom tbody tr:nth-of-type(odd) {
  background-color: #f2f2f2;
}

.table-custom tbody tr:nth-of-type(even) {
  background-color: #fff;
}

.table-custom th,
.table-custom td {
  font-size: 0.8rem;
  padding: 12px 15px;
  border: 1px solid #e6e6e6;
}

.header-cell {
  background-color: #59B089;
  color: white;
  font-weight: bold;
  padding: 10px;
  border-right: 1px solid #e6e6e6;
}

.body-cell {
  padding: 10px;
  border-right: 1px solid #e6e6e6;
}